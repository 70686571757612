
import ValidatePopup from "@/components/ValidatePopup.vue";

// Swiper
import Swiper, { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.css";

//utils
import utilities from "../utilities";
import Toast from "@/components/Toast.vue";

//custom components
import Button from "@/components/Button.vue";

//create component
import { defineComponent } from "vue";

export default defineComponent({
  name: "Product",
  components: {
    Button,
    Toast,
    ValidatePopup,
  },
  data() {
    return {
      product: [] as any[any],
      user: [] as any[any],
      showValidatePopup: false,
      loading: false,
      validated: false,
    };
  },
  async created() {
    //get product id
    const productId = this.$route.params.productId;

    //get variation id
    const variationId = this.$route.params.variationId;

    //get product
    this.product = (await utilities.getItems(
      "products/" + productId + "?fields=*,gallery.*.*,variations.*.*"
    )) as any;

    //set default variation
    this.product.selectedVariation = this.product.variations[0];

    this.product.variations.forEach((variation: any, index: number) => {
      if (variation.id == variationId) {
        this.product.selectedVariation = variation;
      }
    });

    //create gallery swiper
    const galleryOpts = {
      slidesPerView: 1,
      spaceBetween: 0,
      pagination: {
        el: ".swiper-pagination",
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      modules: [Autoplay, Navigation, Pagination],
    };

    // let checkAuth = utilities.checkAuth('producto/' + this.product.id + '/' + this.product.selectedVariation.id + '/' + this.product.name) as any
    // Promise.resolve(checkAuth).then((data) => {
    //     this.user = data.data as any
    // })
    /*if (this.product.category_id != null && this.product.category_id.id == 1) {
      this.showValidatePopup = (await utilities.isValidated()).isValid
        ? false
        : true;
    }*/

    setTimeout(() => {
      const swiper = new Swiper(this.$refs.gallery as any, galleryOpts);
    }, 100);
  },
  async mounted() {
    const toast = this.$refs.toast as any;
    if (this.$route.query["validation"]) {
      toast.show("Credencial validada!", "ok");
    }
    //validación credenciales ok
    let valid = await (await utilities.isValidated()).isValid;
    this.validated = valid;
  },
  methods: {
    setVariation(variarionIndex: number) {
      if (this.product.variations[variarionIndex].status == "published") {
        this.product.selectedVariation =
          this.product.variations[variarionIndex];
      }
    },
    async addProduct(product: any) {
      this.loading = true;
      //let valid = await (await utilities.isValidated()).isValid;
      const toast = this.$refs.toast as any;
      if (product.no_stock != null && product.no_stock === true) {
        toast.show("Lo sentimos, este producto no está disponible");
        this.loading = false;
        return;
      }
      if (
        product.selectedVariation.status == "published" ||
        product.selectedVariation.stock > 0
      ) {
        this.loading = false;
        this.$store.commit("addProduct", product);
        toast.show("Agregaste un producto al carrito");
      } else {
        this.loading = false;
        toast.show("Color no disponible");
      }
    },
    goBack() {
      this.$router.go(-1); // This will take the user back to the previous page
    },
  },
});
