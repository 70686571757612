
  // Utils
  import utilities from "../utilities";
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: "AskCard",
    props: {
      product: {},
    },
    data() {
      return {
        email: '',
        acceptedTerms: false,
        termsError:  'Aceptá los terminos y condiciones para poder suscribirte a nuestro newsletter.',
        validationError:  'El formato de email no es correcto.',
        error: '',
        loading: false,
        hasSuscribed: false,
      };
    },
    watch: {
      acceptedTerms() {
        if (this.acceptedTerms == true && this.error == this.termsError) {
          this.error = ''
        }
      }
    },
    methods: {
      async subscribe() {
        if (this.acceptedTerms == false) {
          this.error = this.termsError;
        }else{
          if (this.ValidEmail(this.email)) {
            this.loading = true;
            this.error = '';
            utilities.subscribe(this.email, '');
            this.loading = false;
            this.hasSuscribed = true;
          }else{
            this.error = this.validationError;
          }
          
        }
      },

      close() {
        this.$emit('close');
      },

      ValidEmail(input :string) {

        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        // if (input.match(validRegex)) {
        //   return true;
        // } else {
        //   

        //   return false;

        // }
        return input.match(validRegex) ? true : false;
      }


    }
  });
  